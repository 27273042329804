const sort = (value, order) => value * (order === 'asc' ? 1 : -1)

export const sortByString =
  (prop, order = 'asc') =>
  (a, b) =>
    sort(a[prop].localeCompare(b[prop]), order)

export const sortByDate =
  (prop, order = 'asc') =>
  (a, b) =>
    sort(new Date(a[prop]) - new Date(b[prop]), order)
