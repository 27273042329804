import featuresJson from '../../generated/features.json'

const FEATURE_KEYS = [
  'appBranding',
  'iubenda',
  'onoffice',
  'company',
  'supportContact',
  'consulting',
  'signup',
  'dataPrivacy',
  'partner',
  'lpEigentumsRadar',
]

const featureConfig = Object.fromEntries(FEATURE_KEYS.map((key) => [key, featuresJson[key] || {}]))

const createFeatureProxy = (key) => {
  return new Proxy(
    {},
    {
      get: (target, prop) => {
        const value = featureConfig[key]?.[prop]
        return value ?? null
      },
    }
  )
}

// Export features as proxied objects
export const appBranding = createFeatureProxy('appBranding')
export const iubenda = createFeatureProxy('iubenda')
export const onoffice = createFeatureProxy('onoffice')
export const company = createFeatureProxy('company')
export const supportContact = createFeatureProxy('supportContact')
export const consulting = createFeatureProxy('consulting')
export const signup = createFeatureProxy('signup')
export const dataPrivacy = createFeatureProxy('dataPrivacy')
export const partner = createFeatureProxy('partner')
export const lpEigentumsRadar = createFeatureProxy('lpEigentumsRadar')

export default featuresJson
