import { createRouter, createWebHistory } from 'vue-router'

import { leadsViewGuard } from '@/modules/leads/quards/leadsGuard'

import { authenticated, publicOnly } from './guards/auth'
import { feature as featureGuard } from './guards/featureGuard'
import { hasRequiredPaymentData } from './guards/paymentGuard'
import { permission as permissionGuard } from './guards/permissionGuard'
import { state as stateGuard } from './guards/state'
import featureNames from './lib/featureNames'
import multiguard from './lib/multiguard'
import AuthCallback from './modules/auth/Callback.vue'
import CompanyCreating from './modules/auth/CompanyCreating.vue'
import Login from './modules/auth/Login/index.vue'
import Logout from './modules/auth/Logout.vue'
import PasswordResetRequest from './modules/auth/PasswordResetRequest/index.vue'
import Dashboard from './views/Dashboard.vue'
import Health from './views/Health.vue'
import Verification from './views/Verification.vue'

// Lazy-loaded components
const LeadDetails = () => import('./views/LeadDetails.vue')
const Leads = () => import('./views/Leads.vue')
const Settings = () => import('./views/Settings.vue')
const Calendar = () => import('./views/Calendar.vue')
const ContentCreator = () => import('./views/ContentCreator.vue')
const ProductPackages = () => import('./views/ProductPackages.vue')
const ProductFinder = () => import('./views/ProductFinder.vue')
const Performance = () => import('./views/Performance.vue')
const Valuation = () => import('./views/Valuation.vue')
const Landingpages = () => import('./views/Landingpages.vue')
const Landingpage = () => import('./views/Landingpage.vue')
const Topics = () => import('./modules/topics/index.vue')
const HelpCenter = () => import('./views/HelpCenter.vue')
const Legal = () => import('./views/Legal.vue')
const StatisticDashboard = () => import('./views/StatisticDashboard.vue')
const Onboarding = () => import('./views/Onboarding.vue')
const OnboardingValidation = () => import('./views/OnboardingValidation.vue')
const Inactive = () => import('./views/Inactive.vue')
const LicenseAgreement = () => import('./views/LicenseAgreement.vue')
const Avv = () => import('./views/Avv.vue')
const Partner = () => import('./views/Partner.vue')
const Privacy = () => import('./views/Privacy.vue')
const Registration = () => import('./views/Registration.vue')
const Signup = () => import('./modules/auth/Signup.vue')
const Register = () => import('./modules/auth/Register/Register.vue')
const PaymentRegistration = () => import('./modules/payment/PaymentRegistration.vue')
const PaymentUpgrade = () => import('./modules/payment/PaymentUpgrade.vue')
const PaymentFinalize = () => import('./modules/payment/PaymentFinalize.vue')
const TokenSignUp = () => import('./modules/auth/TokenSignUp.vue')
const OnofficeActivationSync = () => import('./modules/crm/onoffice/OnofficeActivationSync.vue')
const OnofficeActivationApp = () => import('./modules/crm/onoffice/OnofficeActivationApp.vue')
const OnofficeConfirmation = () => import('./modules/crm/onoffice/OnofficeConfirmation.vue')
const OnofficeServiceApp = () => import('./modules/crm/onoffice/OnofficeServiceApp.vue')
const LinkedinCallback = () => import('./views/LinkedinCallback.vue')
const Products = () => import('./views/Products.vue')

const routes = ({ feature, state, permission }) => [
  {
    path: '/_health',
    name: 'health',
    component: Health,
  },
  {
    path: '/onoffice/activate',
    name: 'onoffice-activate',
    component: OnofficeActivationSync,
  },
  {
    path: '/onoffice/activate/app',
    name: 'onoffice-activate-app',
    component: OnofficeActivationApp,
  },
  {
    path: '/onoffice/confirmation/:token',
    name: 'onoffice-confirmation',
    component: OnofficeConfirmation,
    beforeEnter: multiguard([authenticated, feature(featureNames.CRM), state(['ACTIVE'])]),
  },
  {
    path: '/onoffice/service/app',
    name: 'onoffice-service-app',
    component: OnofficeServiceApp,
  },
  {
    path: '/',
    name: 'home',
    redirect: {
      name: 'login',
    },
  },
  {
    path: '/flowfact/:token?',
    name: 'flowfact',
    props: { partner: 'FLOWFACT' },
    component: TokenSignUp,
    beforeEnter: publicOnly,
  },
  {
    path: '/propstack/:token?',
    name: 'propstack',
    props: { partner: 'PROPSTACK' },
    component: TokenSignUp,
    beforeEnter: publicOnly,
  },
  {
    path: '/auth/login',
    name: 'login',
    component: Login,
    beforeEnter: publicOnly,
  },
  {
    path: '/auth/logout',
    name: 'logout',
    component: Logout,
  },
  {
    path: '/auth/signup/:token?',
    name: 'signup',
    component: Signup,
    beforeEnter: multiguard([publicOnly, feature(featureNames.SIGNUP)]),
  },
  {
    path: '/auth/register',
    name: 'register',
    component: Register,
    beforeEnter: multiguard([publicOnly, feature(featureNames.SIGNUP)]),
  },
  {
    path: '/payment',
    name: 'payment',
    component: PaymentRegistration,
    beforeEnter: multiguard([
      authenticated,
      hasRequiredPaymentData,
      state(['REGISTRATION.BILLING']),
    ]),
  },
  {
    path: '/change-package-payment',
    name: 'change-package-payment',
    component: PaymentUpgrade,
    beforeEnter: multiguard([authenticated, state(['ACTIVE'])]),
  },
  {
    path: '/payment-finalize/:action?',
    name: 'payment-finalize',
    component: PaymentFinalize,
    beforeEnter: multiguard([authenticated]),
  },
  {
    path: '/registration',
    name: 'registration',
    component: Registration,
    beforeEnter: multiguard([
      authenticated,
      feature(featureNames.SIGNUP),
      state(['SELF_ONBOARDING']),
    ]),
  },
  {
    path: '/auth/callback',
    name: 'auth-callback',
    component: AuthCallback,
  },
  {
    path: '/company-creation',
    name: 'company-creation',
    component: CompanyCreating,
  },
  {
    path: '/products',
    name: 'products',
    component: Products,
  },
  {
    path: '/signup-markero/:step',
    name: 'signup-markero',
    beforeEnter: multiguard([authenticated, state(['REGISTRATION.ANAMNESIS'])]),
    component: ProductFinder,
    meta: {
      tracking: {
        useActualPath: true,
      },
    },
  },
  {
    path: '/product-packages',
    name: 'product-packages',
    component: ProductPackages,
  },
  {
    path: '/auth/password-reset',
    name: 'password-reset-request',
    component: PasswordResetRequest,
    beforeEnter: publicOnly,
  },
  {
    path: '/onboarding',
    name: 'onboarding',
    redirect: {
      path: '/onboarding/company',
    },
  },
  {
    path: '/onboarding/:step',
    name: 'onboardingStep',
    component: Onboarding,
    beforeEnter: multiguard([authenticated, state(['ONBOARDING.USER'])]),
    meta: {
      tracking: {
        useActualPath: true,
      },
    },
  },
  {
    path: '/onboarding-validation',
    name: 'onboardingValidation',
    component: OnboardingValidation,
    beforeEnter: multiguard([authenticated, state(['ONBOARDING.VALIDATION'])]),
  },
  {
    path: '/inactive',
    name: 'inactive',
    component: Inactive,
    beforeEnter: multiguard([authenticated, state(['INACTIVE', 'CREATED'])]),
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: Dashboard,
    beforeEnter: multiguard([authenticated, state(['ACTIVE'])]),
  },
  {
    path: '/leads',
    name: 'leads',
    component: Leads,
    beforeEnter: multiguard([
      authenticated,
      feature(featureNames.LEAD),
      permission('lead:read'),
      state(['ACTIVE']),
      leadsViewGuard,
    ]),
    meta: {
      tracking: {
        ignorePageView: true,
      },
    },
  },
  {
    path: '/leads/:view(list|pipeline)',
    name: 'leads-view',
    component: Leads,
    beforeEnter: multiguard([
      authenticated,
      feature(featureNames.LEAD),
      permission('lead:read'),
      state(['ACTIVE']),
    ]),
  },
  {
    path: '/leads/:id',
    name: 'lead-details',
    component: LeadDetails,
    beforeEnter: multiguard([
      authenticated,
      feature(featureNames.LEAD),
      permission('lead:read'),
      state(['ACTIVE']),
    ]),
  },
  {
    path: '/profile',
    name: 'profile',
    redirect: {
      path: '/settings/profile',
    },
  },
  {
    path: '/landingpages',
    name: 'landingpages',
    component: Landingpages,
    beforeEnter: multiguard([
      authenticated,
      feature(featureNames.LANDINGPAGE),
      permission('material:read'),
      state(['ACTIVE']),
    ]),
  },
  {
    // Put this route before the slug route
    path: '/landingpages/:view(tools|guides|checklists|campaigns|templates|newsflash)',
    name: 'landingpages-view',
    component: Landingpages,
    beforeEnter: multiguard([
      authenticated,
      feature(featureNames.LANDINGPAGE),
      permission('material:read'),
      state(['ACTIVE']),
    ]),
  },

  // Landingpage component will be used for the new page - Ad Materials
  {
    path: '/landingpages/:slug',
    name: 'landingpage-details',
    component: Landingpage,
    beforeEnter: multiguard([
      authenticated,
      feature(featureNames.LANDINGPAGE),
      permission('material:read'),
      state(['ACTIVE']),
    ]),
    meta: {
      tracking: {
        useActualPath: true,
      },
    },
  },
  {
    path: '/downloads',
    name: 'downloads',
    redirect: {
      name: 'landingpages',
    },
  },
  {
    path: '/topics',
    name: 'topics',
    component: Topics,
    beforeEnter: multiguard([authenticated, state(['ACTIVE'])]),
  },
  {
    path: '/valuation',
    name: 'valuation',
    component: Valuation,
    beforeEnter: multiguard([
      authenticated,
      feature(featureNames.VALUATION, (config) => config.isInAppValuationEnabled),
      permission('valuation:read'),
      state(['ACTIVE']),
    ]),
  },
  {
    path: '/content-creator',
    name: 'content-creator',
    component: ContentCreator,
    beforeEnter: multiguard([
      authenticated,
      feature(featureNames.CONTENT_CREATOR),
      permission('ai:read'),
      state(['ACTIVE']),
    ]),
  },
  {
    path: '/performance',
    name: 'performance',
    component: Performance,
    beforeEnter: multiguard([
      authenticated,
      feature(featureNames.PERFORMANCE_MARKETING),
      permission('performance:read'),
      state(['ACTIVE']),
    ]),
  },
  {
    path: '/statistic-dashboard',
    name: 'statistic-dashboard',
    component: StatisticDashboard,
    beforeEnter: multiguard([
      authenticated,
      feature(featureNames.STATISTIC_DASHBOARD),
      permission('lead:read'),
      state(['ACTIVE']),
    ]),
  },
  {
    path: '/support',
    name: 'support',
    component: HelpCenter,
    beforeEnter: multiguard([authenticated, permission('support:read'), state(['ACTIVE'])]),
  },
  {
    path: '/partner',
    name: 'partner',
    component: Partner,
    beforeEnter: multiguard([
      authenticated,
      feature(featureNames.PARTNER),
      permission('partner:read'),
      state(['ACTIVE']),
    ]),
  },
  {
    path: '/settings',
    name: 'settings',
    redirect: {
      path: '/settings/company',
    },
  },
  {
    path: '/settings/:section',
    name: 'Settings',
    component: Settings,
    beforeEnter: multiguard([authenticated, permission('settings:read'), state(['ACTIVE'])]),
    meta: {
      tracking: {
        useActualPath: true,
      },
    },
  },
  {
    path: '/verify/:id',
    name: 'verify',
    component: Verification,
    beforeEnter: multiguard([authenticated, state(['ACTIVE'])]),
  },
  {
    path: '/linkedin-callback',
    name: 'linkedin-callback',
    component: LinkedinCallback,
    beforeEnter: multiguard([authenticated, state(['ACTIVE'])]),
  },
  {
    path: '/calendar',
    name: 'calendar',
    component: Calendar,
    beforeEnter: multiguard([
      authenticated,
      feature(featureNames.EVENT_CALENDAR),
      permission('calendar:read'),
    ]),
  },
  {
    path: '/legal',
    name: 'legal',
    component: Legal,
    beforeEnter: multiguard([authenticated]),
  },
  {
    path: '/license-agreement',
    name: 'licenseAgreement',
    component: LicenseAgreement,
    beforeEnter: multiguard([
      authenticated,
      feature(featureNames.LICENSE_AGREEMENT),
      permission('legal:read'),
    ]),
  },
  {
    path: '/avv',
    name: 'avv',
    component: Avv,
    beforeEnter: multiguard([authenticated, permission('legal:read')]),
  },
  {
    path: '/privacy',
    name: 'privacy',
    component: Privacy,
    beforeEnter: multiguard([
      authenticated,
      feature(featureNames.APP_BRANDING, (config) => config.showPrivacyArea),
      permission('legal:read'),
    ]),
  },
  {
    path: '/:pathMatch(.*)*',
    name: '404',
    redirect: {
      name: 'home',
    },
  },
]

const createApolloRouter = (apollo) => {
  const router = createRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    routes: routes({
      feature: (feature, activeCheckFn) => featureGuard(apollo, feature, activeCheckFn),
      state: (states) => stateGuard(apollo, states),
      permission: (permission) => permissionGuard(apollo, permission),
    }),
  })

  let trackingInitialized = false

  router.beforeEach((to, from, next) => {
    if (!trackingInitialized && router.app?.config?.globalProperties?.$tracking) {
      // Set up page view tracking once app is available
      router.afterEach((to) => {
        if (!to.meta.tracking?.ignorePageView) {
          router.app.config.globalProperties.$tracking.pageView(to)
        }
      })
      trackingInitialized = true
    }
    next()
  })

  return router
}

export default createApolloRouter
