<template>
  <div class="mt-4">
    <v-card
      border
      class="pt-6 root"
    >
      <h2 class="pl-4 mb-3 text-h6">
        {{ $t('lead-interactivity.title') }}
      </h2>

      <template v-if="!this.$apollo.queries.rawData.loading">
        <apex-chart
          v-if="enoughData"
          height="80%"
          :options="chartOptions"
          :series="series"
        />
        <div
          v-else
          class="not-enough-data text-h6 font-weight-regular text-grey"
        >
          {{ $t('lead-interactivity.body-texts.0') }}<br />
          {{ $t('lead-interactivity.body-texts.1') }}
        </div>
      </template>
    </v-card>
  </div>
</template>

<script>
import ApexChart from 'vue3-apexcharts'
import { useTheme } from 'vuetify'

import LEAD_INTERACTIVITY from './LeadInteractivity.gql'

const MIN_DATAPOINTS = 1

export default {
  components: {
    ApexChart,
  },
  setup() {
    const theme = useTheme()

    const primaryColor = theme.global.current.value.colors.primary

    return { theme, primaryColor }
  },
  computed: {
    chartOptions() {
      return {
        chart: {
          type: 'heatmap',
          parentHeightOffset: 0,
          toolbar: {
            show: false,
          },
          zoom: {
            enabled: false,
          },
        },
        colors: [this.primaryColor],
        dataLabels: {
          enabled: false,
        },
        xaxis: {
          title: {
            text: this.$t('labels.time'),
          },
        },
        yaxis: {
          reversed: true,
        },
      }
    },
    enoughData() {
      const total = this.rawData?.reduce((weekTotal, week) => {
        return weekTotal + week.hours.reduce((dayTotal, hour) => dayTotal + hour, 0)
      }, 0)
      return total > MIN_DATAPOINTS
    },
    series() {
      const weekdays = {
        en: ['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su'],
        de: ['Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa', 'So'],
      }[this.$i18n.locale]

      return weekdays.map((name, index) => {
        const week = this.rawData?.find((date) => date.weekday === index + 1)
        const hours = week?.hours ?? []
        return {
          name,
          data: hours.map((count, hour) => ({
            x: `${hour}`, // if it's a string xaxis.type will be category
            y: count,
          })),
        }
      })
    },
  },
  apollo: {
    rawData: {
      query: LEAD_INTERACTIVITY,
      variables() {
        return {
          companyId: this.$auth.user.companyId,
        }
      },
    },
  },
}
</script>

<style scoped>
.root {
  height: 400px;
}

.not-enough-data {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  text-align: center;
}
</style>
