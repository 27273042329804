<template>
  <v-snackbar
    v-model="isVisible"
    :color="color"
    :timeout="timeout"
  >
    <div class="d-flex justify-space-between">
      <div class="d-flex align-center row pa-2">
        <div class="d-flex align-center">
          {{ text }}
        </div>
        <div
          v-for="element in texts"
          :key="element.text || element"
          class="d-flex align-center"
        >
          <router-link
            v-if="element.link"
            :to="element.link"
            class="px-2 pointer text-white font-weight-bold"
          >
            {{ element.text }}
          </router-link>
          <div v-else>
            {{ element.text || element }}
          </div>
        </div>
      </div>
      <v-btn
        icon
        size="small"
        color="white"
        @click="isVisible = false"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </div>
  </v-snackbar>
</template>

<script>
import bus, { eventNames } from '@/lib/eventBus'

export default {
  data() {
    return {
      isVisible: false,
      text: '',
      texts: [],
      color: '',
      timeout: 5000,
      hideTimeout: null,
    }
  },
  mounted() {
    bus.$on(eventNames.SHOW_SNACKBAR, (data) => {
      this.showSnackbar(data.color, data.text, data.time, data.texts)
    })
  },
  methods: {
    showSnackbar(newColor, newText, newTime, newTexts = []) {
      this.color = newColor
      this.text = newText
      this.texts = newTexts
      this.timeout = newTime || 5000
      this.isVisible = true
      this.hideTimeout = setTimeout(() => (this.timeout = 5000), newTime || 5000)
    },
  },
  unmounted() {
    clearTimeout(this.hideTimeout)
  },
}
</script>
