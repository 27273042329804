<template>
  <v-btn
    rounded="md"
    class="upgrade-button"
    color="primary"
    :to="{ name: 'Settings', params: { section: 'contract' }, query: { showUpgrade: '1' } }"
    @click="trackClick"
  >
    <v-icon>mdi-one-up</v-icon>
    <v-expand-x-transition>
      <span
        v-if="showLabel"
        class="upgrade-button-label ml-2"
        >{{ label }}</span
      >
    </v-expand-x-transition>
  </v-btn>
</template>

<script lang="ts" setup>
defineProps<{
  showLabel?: boolean
  label?: string
}>()

const emit = defineEmits<{
  // eslint-disable-next-line no-unused-vars
  (e: 'track-click'): void
}>()

const trackClick = () => {
  emit('track-click')
}
</script>

<style scoped>
.upgrade-button {
  text-transform: unset;
  font-weight: 700;
  padding: 0 8px !important;
  max-width: 100%;
  min-width: unset;
}
</style>
