<template>
  <div class="d-flex">
    <apex-chart
      :options="chartOptions"
      :series="chartSeries"
    />
  </div>
</template>

<script>
import ApexChart from 'vue3-apexcharts'

export default {
  components: { ApexChart },
  props: {
    score: {
      type: Number,
      default: 0,
    },
    label: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      chartOptions: {
        chart: {
          height: 'auto',
          type: 'radialBar',
          offsetY: -10,
        },
        plotOptions: {
          radialBar: {
            startAngle: -90,
            endAngle: 90,
            track: {
              background: '#eeeeee',
              strokeWidth: '97%',
              margin: 0, // margin is in pixels
              dropShadow: {
                enabled: true,
                top: 2,
                left: 0,
                blur: 1,
                opacity: 0.5,
                color: '#9e9e9e',
              },
            },
            dataLabels: {
              show: true,
              name: {
                offsetY: 20,
                show: true,
                color: '#9e9e9e',
                fontSize: '12px',
              },
              value: {
                offsetY: -20,
                formatter: function (val) {
                  return `${parseInt(val)}%`
                },
                color: '#9e9e9e',
                fontSize: '18px',
                show: true,
              },
            },
          },
        },
        fill: {
          type: 'gradient',
          gradient: {
            shade: 'dark',
            type: 'horizontal',
            shadeIntensity: 0.5,
            gradientToColors: [this.$vuetify.theme.global?.current?.colors.primary],
            opacityFrom: 1,
            opacityTo: 1,
            stops: [0, 100],
          },
        },
        labels: [this.label],
      },
    }
  },
  computed: {
    chartSeries() {
      return [this.score * 100]
    },
  },
}
</script>
