<template>
  <div
    v-if="productType"
    class="d-flex flex-column pt-2"
  >
    <div class="d-flex align-center justify-space-between mx-2">
      <div class="d-flex align-center">
        <div class="mr-2 mt-1">
          <badge-bronze v-if="isBronzePlan" />
          <badge-silver v-if="isSilverPlan" />
          <badge-gold v-if="isGoldPlan" />
        </div>
        <h3>{{ planName }}</h3>
      </div>
    </div>

    <!-- Main Progress Section -->
    <div class="mt-4">
      <v-progress-linear
        :aria-valuemin="0"
        :aria-valuemax="100"
        :aria-valuenow="calculatedUsage"
        :color="getProgressColor(calculatedUsage)"
        :model-value="calculatedUsage"
        height="8"
      />
      <div class="d-flex justify-space-between align-center mt-1">
        <span class="text-caption text-grey">
          {{ Math.round(calculatedUsage) }}% {{ $t('leads.table.of') }} 100%
        </span>
        <v-btn
          v-if="viewMoreEnabled && hasLimitedFeatures"
          variant="text"
          compact
          class="text-caption pa-0 px-1"
          color="primary"
          @click="showDetails = !showDetails"
        >
          {{
            showDetails
              ? $t('navigation.usage-counter.view-less')
              : $t('navigation.usage-counter.view-details')
          }}
        </v-btn>
      </div>
    </div>

    <!-- Detailed Usage Sections -->
    <div
      v-if="viewMoreEnabled && showDetails"
      class="mt-4"
    >
      <!-- Text Generations - Only show if not unlimited -->
      <div
        v-if="usageLimit.maxTextGenerationsPerMonth !== -1"
        class="mb-4"
      >
        <v-progress-linear
          :color="getProgressColor(getUsagePercentage('textGenerations'))"
          :model-value="getUsagePercentage('textGenerations')"
          height="8"
        />
        <div class="text-caption text-grey mt-1">
          {{
            $t('navigation.usage-counter.limited-text', {
              count: currentUsage.textGenerations,
              limit: usageLimit.maxTextGenerationsPerMonth,
            })
          }}
        </div>
      </div>

      <!-- AI Images - Only show if not unlimited -->
      <div
        v-if="usageLimit.aiImageLimit !== -1"
        class="mb-4"
      >
        <v-progress-linear
          :color="getProgressColor(getUsagePercentage('aiImages'))"
          :model-value="getUsagePercentage('aiImages')"
          height="8"
        />
        <div class="text-caption text-grey mt-1">
          {{ currentUsage.aiImages }} {{ $t('leads.table.of') }} {{ usageLimit.aiImageLimit }}
          {{ $t('content-creator.limits.generated-images') }}
        </div>
      </div>

      <!-- Topics - Only show if not unlimited -->
      <div
        v-if="usageLimit.maxActiveTopics !== -1"
        class="mb-4"
      >
        <v-progress-linear
          :color="getProgressColor(getUsagePercentage('topics'))"
          :model-value="getUsagePercentage('topics')"
          height="8"
        />
        <div class="text-caption text-grey mt-1">
          {{
            $t('navigation.usage-counter.limited-topics', {
              count: currentUsage.topics,
              limit: usageLimit.maxActiveTopics,
            })
          }}
        </div>
      </div>
    </div>

    <v-btn
      v-if="!isGoldPlan && !$vuetify.display.mdAndDown"
      :to="{
        name: 'Settings',
        params: { section: 'contract' },
        query: { showUpgrade: '1' },
      }"
      class="mt-4"
      color="primary"
      block
      @click="$tracking.event('Sidebar', 'Clicked', 'Upgrade')"
    >
      <v-icon class="mr-2"> mdi-one-up </v-icon>
      {{ $t('navigation.usage-counter.upgrade-plan') }}
    </v-btn>
  </div>
</template>

<script>
import { ProductType as ProductTypes } from '@/lib/productTypes'
import featureMixin from '@/mixins/feature'
import GET_ACTIVE_TOPICS from '@/modules/contentCreator/creator/queries/getActiveTopics.gql'
import GET_GENERATION_STATS from '@/modules/contentCreator/creator/queries/getGenerationStats.gql'

import badgeBronze from './design-elements/badgeBronze.vue'
import badgeGold from './design-elements/badgeGold.vue'
import badgeSilver from './design-elements/badgeSilver.vue'

export default {
  components: {
    badgeBronze,
    badgeGold,
    badgeSilver,
  },
  mixins: [featureMixin],
  props: {
    productType: {
      default: null,
      type: String,
      validator: (value) => {
        return Object.values(ProductTypes).includes(value)
      },
    },
    weights: {
      default() {
        return {
          aiImages: 1,
          textGenerations: 1,
          topics: 1,
        }
      },
      type: Object,
      validator: (value) => {
        return Object.values(value).every((weight) => typeof weight === 'number' && weight > 0)
      },
    },
    viewMoreEnabled: {
      default: false,
      type: Boolean,
    },
    errorThreshold: {
      default: 75,
      type: Number,
    },
  },
  data() {
    return {
      ProductTypes,
      showDetails: false,
      textData: {
        BASIC: 'Basic',
        EXPERT: 'Expert',
        LIGHT: 'Light',
        PIONEER: 'Pioneer',
        ADVANCED: 'Advanced',
        BEGINNER: 'Beginner',
      },
    }
  },
  computed: {
    planName() {
      const names = {
        [ProductTypes.BASIC]: this.textData.BASIC,
        [ProductTypes.EXPERT]: this.textData.EXPERT,
        [ProductTypes.LIGHT]: this.textData.LIGHT,
        [ProductTypes.PIONEER]: this.textData.PIONEER,
        [ProductTypes.ADVANCED]: this.textData.ADVANCED,
        [ProductTypes.BEGINNER]: this.textData.BEGINNER,
      }
      return names[this.productType] || ''
    },
    isBronzePlan() {
      if (!this.productType) return false
      const entryPlans = [ProductTypes.BEGINNER, ProductTypes.LIGHT, ProductTypes.PIONEER]
      return entryPlans.includes(this.productType)
    },
    isSilverPlan() {
      if (!this.productType) return false
      const lightPlans = [ProductTypes.BASIC]
      return lightPlans.includes(this.productType)
    },
    isGoldPlan() {
      if (!this.productType) return false
      const expertPlans = [ProductTypes.EXPERT, ProductTypes.ADVANCED]
      return expertPlans.includes(this.productType)
    },
    usageLimit() {
      if (!this.getFeature('topic') || !this.getFeature(this.featureNames.CONTENT_CREATOR)) {
        return {
          maxActiveTopics: 0,
          maxTextGenerationsPerMonth: 0,
          aiImageLimit: 0,
        }
      }
      const config = this.getFeature('topic').config
      const { maxActiveTopics } = config
      const feature = this.getFeature(this.featureNames.CONTENT_CREATOR)
      const { maxTextGenerationsPerMonth, aiImageLimit } = feature.config
      const usageLimits = {
        maxActiveTopics,
        maxTextGenerationsPerMonth,
        aiImageLimit,
      }
      return usageLimits
    },
    currentUsage() {
      // Default values if neither generationStats nor topics exist
      if (!this.generationStats && !this.topics) {
        return {
          aiImages: 0,
          textGenerations: 0,
          topics: 0,
        }
      }

      const { imageCount = 0, textCount = 0 } = this.generationStats || {}
      const topicsLength = this.topics?.length || 0

      return {
        aiImages: imageCount,
        textGenerations: textCount,
        topics: topicsLength,
      }
    },
    hasLimitedFeatures() {
      return (
        this.usageLimit.maxTextGenerationsPerMonth !== -1 ||
        this.usageLimit.aiImageLimit !== -1 ||
        this.usageLimit.maxActiveTopics !== -1
      )
    },
    actualUsagePercentage() {
      if (!this.usageLimit || !this.currentUsage) return 0

      const metrics = [
        {
          name: 'topics',
          current: this.currentUsage.topics,
          limit: this.usageLimit.maxActiveTopics,
          weight: this.weights.topics,
        },
        {
          name: 'aiImages',
          current: this.currentUsage.aiImages,
          limit: this.usageLimit.aiImageLimit,
          weight: this.weights.aiImages,
        },
        {
          name: 'textGenerations',
          current: this.currentUsage.textGenerations,
          limit: this.usageLimit.maxTextGenerationsPerMonth,
          weight: this.weights.textGenerations,
        },
      ]

      // Filter out metrics with invalid limits (-1)
      const validMetrics = metrics.filter(({ limit }) => limit !== -1)

      // Early return if no valid metrics
      if (validMetrics.length === 0) return 0

      // Calculate total weight of valid metrics
      const totalWeight = validMetrics.reduce((sum, { weight }) => sum + weight, 0)

      if (totalWeight === 0) return 0

      // Calculate weighted sum of usage percentages
      const weightedSum = validMetrics.reduce((sum, { current, limit, weight }) => {
        // Guard against negative values
        const safeLimit = Math.max(limit, 1)
        const safeCurrent = Math.max(current, 0)
        const usagePercentage = safeCurrent / safeLimit
        return sum + usagePercentage * weight
      }, 0)

      // Return final percentage, capped at 100%
      return Math.min(100, (weightedSum / totalWeight) * 100)
    },
    calculatedUsage() {
      return this.mapProgressPercentage(this.actualUsagePercentage)
    },
  },
  methods: {
    calculatePercentage(current, max) {
      if (!max || max === -1) return 0
      const actualPercentage = (current / max) * 100
      return this.mapProgressPercentage(actualPercentage)
    },
    mapProgressPercentage(actualPercentage) {
      // Map the actual percentage to visual percentage
      if (actualPercentage <= 25) {
        // 0-25% maps to 0-50%
        return actualPercentage * 2
      } else if (actualPercentage <= 50) {
        // 25-50% maps to 50-75%
        return 50 + (actualPercentage - 25) * 1
      } else if (actualPercentage <= 75) {
        // 50-75% maps to 75-90%
        return 75 + (actualPercentage - 50) * 0.6
      } else {
        // 75-100% maps to 90-100%
        return 90 + (actualPercentage - 75) * 0.4
      }
    },
    getUsagePercentage(type) {
      // Early return for unlimited text generations
      if (type === 'textGenerations' && this.usageLimit.maxTextGenerationsPerMonth === -1) {
        return 0
      }

      const current = this.currentUsage[type]

      // Map usage types to their corresponding limits
      const limitMap = {
        textGenerations: this.usageLimit.maxTextGenerationsPerMonth,
        aiImages: this.usageLimit.aiImageLimit,
        topics: this.usageLimit.maxActiveTopics,
      }

      // Get the limit from the map, defaulting to 0 if type is not found
      const max = limitMap[type] ?? 0

      return this.calculatePercentage(current, max)
    },
    getProgressColor(percentage) {
      return percentage >= this.errorThreshold ? 'error' : 'primary'
    },
  },
  apollo: {
    generationStats: {
      query: GET_GENERATION_STATS,
      error(error) {
        console.error('Failed to fetch stats:', error)
      },
    },
    topics: {
      query: GET_ACTIVE_TOPICS,
      fetchPolicy: 'cache-and-network',
      notifyOnNetworkStatusChange: true,
      result({ data }) {
        if (data?.topics) {
          this.$nextTick(() => {
            this.topics = [...data.topics]
          })
        }
      },
      error(error) {
        console.error('Failed to fetch stats:', error)
      },
    },
  },
}
</script>
