<template>
  <v-card
    class="d-flex flex-column h-full"
    border
  >
    <v-card-title class="text-subtitle-2 text-md-h6 text-lg-h6">
      {{ $t('mission-control.progress') }}
    </v-card-title>
    <v-card-text class="d-flex justify-center mt-8">
      <v-progress-circular
        rotate="-90"
        :size="$vuetify.display.mdAndUp ? 200 : 160"
        width="16"
        :model-value="completedTodosPercentage"
        color="primary"
      >
        <span class="text-h3"> {{ completedTodosPercentage }}% </span>
      </v-progress-circular>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: {
    completedTodosPercentage: {
      type: Number,
      required: true,
    },
  },
}
</script>
