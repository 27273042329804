export const getAllNavItems = (isPlatformMarkero, languageCode) => {
  const navItems = []

  if (isPlatformMarkero) {
    const news = createNewsNavItem()
    navItems.push(news)
  }

  const support = createSupportNavItem(isPlatformMarkero, languageCode)
  navItems.push(support)

  const legalNavItem = {
    label: 'navigation.labels.legal',
    trackingLabel: 'Legal',
    to: '/legal',
    onboarding: true,
    icon: 'mdi-file-document-outline',
    permission: 'legal:read',
  }
  navItems.push(legalNavItem)

  return navItems
}

const createSupportNavItem = (isPlatformMarkero, languageCode) => {
  const support = {
    label: 'navigation.labels.support',
    trackingLabel: 'HelpCenter',
    icon: 'mdi-lifebuoy',
    onboarding: false,
    permission: 'support:read',
  }

  if (isPlatformMarkero && import.meta.env.VITE_APP_PRODUCT_FRUITS_HELP_CENTER) {
    support.href = new URL(
      languageCode,
      import.meta.env.VITE_APP_PRODUCT_FRUITS_HELP_CENTER
    ).toString()
    support.target = '_blank'
  } else {
    support.to = '/support'
  }

  return support
}

const createNewsNavItem = () => {
  return {
    label: 'navigation.labels.news',
    trackingLabel: 'News',
    icon: 'mdi-bell-outline',
    onboarding: false,
  }
}

export const navItemsBottimmo = (
  isPlatformMarkero,
  languageCode,
  inOnboarding,
  isInactive,
  features,
  role
) => {
  return inOnboarding || isInactive
    ? getActiveNavItems(
        getAllNavItems(isPlatformMarkero, languageCode).filter(({ onboarding }) => onboarding),
        features,
        role
      )
    : getActiveNavItems(getAllNavItems(isPlatformMarkero, languageCode), features, role)
}

export const getActiveNavItems = (items, features, role) => {
  return items.filter(({ feature: featureSlug, checkConfigIsActive, permission }) => {
    const feature = featureSlug ? features.feature(featureSlug) : null
    if (
      feature &&
      (!feature.isActive || (checkConfigIsActive && !checkConfigIsActive(feature.config)))
    ) {
      return false
    }

    if (permission && !role.isAuthorizedFor(permission)) {
      return false
    }

    return true
  })
}
