<template>
  <v-card
    border
    class="mt-4"
  >
    <div
      ref="map"
      class="map"
    />
    <MapLegend
      v-if="map"
      :map="map"
      :markers="markers"
      @apply-filter="applyFilterToMap"
    />
  </v-card>
</template>

<script>
/* global google */
import { MarkerClusterer } from '@googlemaps/markerclusterer'

import status from '@/lib/leadStatus'
import { status as ValuationStatus } from '@/lib/valuation'
import currencyMixin from '@/mixins/currency'
import mapMixin from '@/mixins/map'

import MapLegend from './MapLegend'

export default {
  components: { MapLegend },
  mixins: [currencyMixin, mapMixin],
  props: {
    leads: {
      type: Array,
      default: null,
    },
  },
  async mounted() {
    this.loadGoogleMaps()
    await this.googleMapsLoaded()

    const { lat, lng } = this.getDefaultCenter(this.$auth.user.locale)
    const zoom = this.getDefaultZoom(this.$auth.user.locale)

    this.map = new google.maps.Map(this.$refs.map, {
      center: { lat, lng },
      zoom,
      fullscreenControl: true,
      streetViewControl: false,
      mapTypeControl: false,
    })

    const infoWindow = new google.maps.InfoWindow()

    google.maps.event.addListener(infoWindow, 'domready', () => {
      const links = document.getElementsByClassName('leadDetailLink')
      for (const link of links) {
        link.addEventListener('click', (event) =>
          this.$router.push('/leads/' + event.target.getAttribute('data'))
        )
      }
    })

    this.populateMap(infoWindow)
    this.createMarkerClusterer(infoWindow)
  },
  methods: {
    populateMap(infoWindow) {
      this.markers.forEach((marker) => {
        marker.setMap(null)
      })
      this.circles.forEach((circle) => {
        circle.setMap(null)
      })
      this.markers = []
      this.circles = []
      const bounds = new google.maps.LatLngBounds()
      this.leads.forEach((lead) =>
        lead.conversions.forEach(({ valuation, propertyRequest }) => {
          if ((valuation && valuation.status === ValuationStatus.COMPLETED) || propertyRequest) {
            let address
            let type
            let price
            let radius

            if (valuation && valuation.status === ValuationStatus.COMPLETED) {
              const avg = valuation.results?.property?.value
              const currency = valuation.results?.currency
              address = {
                ...valuation.inputs.address,
                ...valuation.inputs.coordinates,
              }
              type = lead.currentStatus === 'new' ? 'active' : 'inactive'
              price = avg ? this.formatPrice(avg, currency) : 0
            } else if (propertyRequest) {
              address = {
                ...propertyRequest.property.location.coordinates,
                radius: propertyRequest.property.location.radius,
                description: propertyRequest.property.location.description,
              }
              type = 'propertyRequest'
              price = `${this.formatPrice(propertyRequest.property.minPrice)} - ${this.formatPrice(propertyRequest.property.maxPrice)}`
              radius = propertyRequest.property.location.radius
            }

            const marker = this.createMarker({ type, address, radius, lead })
            let content

            if (type === 'propertyRequest') {
              content = this.createPropertyRequestMarkerInfo({ lead, address, price })
            } else if (['inactive', 'active'].includes(type)) {
              content = this.createValuationMarkerInfo({ lead, address, price })
            }

            marker.addListener('click', function () {
              infoWindow.setContent(content)
              infoWindow.open(this.map, marker)
            })

            this.markers.push(marker)
            bounds.extend(marker.position)

            if (radius) {
              const circle = this.createRadius(marker)
              this.circles.push(circle)
              bounds.union(circle.getBounds(), 0)
            }
          }
        })
      )

      if (this.markers.length > 1) {
        this.map.fitBounds(bounds)
      }
    },

    createMarkerClusterer(infoWindow) {
      const renderer = {
        render: ({ markers, _position: position }) => {
          return new google.maps.Marker({
            position: {
              lat: position.lat(),
              lng: position.lng(),
            },
            icon: {
              url: '/img/marker/multiple.svg',
              scaledSize: { height: 50, width: 50 },
            },
            label: { text: String(markers.length), color: 'white', fontSize: '15px' },
          })
        },
      }

      this.markerClusterer = new MarkerClusterer({ markers: this.markers, map: this.map, renderer })
      this.markerClusterer.onClusterClick = (_, clickedCluster) => {
        return this.clusterClick(clickedCluster, this.markerClusterer, infoWindow)
      }
    },

    clusterClick(clickedCluster, markerClusterer, clusterInfoWindow) {
      var zoom = this.map.getZoom()
      var maxZoom = 15
      if (zoom >= maxZoom && markerClusterer.clusters.length > 1) {
        clusterInfoWindow.setContent(this.createMultipleMarkerInfo(clickedCluster.markers))
        clusterInfoWindow.open(this.map, clickedCluster.marker)
        return true
      } else {
        this.map.setCenter(clickedCluster.marker.position)
        this.map.setZoom(zoom + 2)
        return false
      }
    },

    createPropertyRequestMarkerInfo({ lead, address, price }) {
      return (
        '<div style="width:200px">' +
        `<h2>${price}</h2>` +
        '<br>' +
        '<div>' +
        `${lead.firstname || ''} ${lead.lastname || ''}` +
        '</div>' +
        '<div>' +
        `${address.description}` +
        '</div>' +
        '<br>' +
        '<div>' +
        `${status[lead.currentStatus] || ''}` +
        '</div>' +
        '<br>' +
        `<b><a class="leadDetailLink" data="${lead.id}">${this.$t('labels.lead-map.contact-info')}</a></b>` +
        '</div>'
      )
    },

    createValuationMarkerInfo({ lead, address, price }) {
      return (
        '<div style="width:200px">' +
        `<h2>${price}</h2>` +
        '<br>' +
        '<div>' +
        `${lead.firstname || ''} ${lead.lastname || ''}` +
        '</div>' +
        '<div>' +
        `${address.street} ${address.houseNumber}` +
        '</div>' +
        '<div>' +
        `${address.zip} ${address.city}` +
        '</div>' +
        '<br>' +
        '<div>' +
        `${status[lead.currentStatus] || ''}` +
        '</div>' +
        '<br>' +
        `<b><a class="leadDetailLink" data="${lead.id}">${this.$t('labels.lead-map.contact-info')}</a></b>` +
        '</div>'
      )
    },

    createMultipleMarkerInfo(markers) {
      let markersList = ''
      markers.forEach((marker) => {
        markersList += `
              <div style="max-width: 100%; overflow:hidden" class="d-flex py-2">
                    <img width="20px" height="20px" src="${marker.icon.url}" alt="type icon"/>
                    <span style="max-width: 100%" class="pt-1"><b><a class="leadDetailLink" data="${marker.lead.id}">${marker.lead.name || marker.lead.email}</a></b></span>
              </div>
            `
      })
      return `<div style="width:200px; max-height: 300px">
        <p><b>${markers.length}</b> ${this.$t('labels.lead-map.estates')}</p>
        <div>
          ${markersList}
        </div>
      </div>
      `
    },
  },
}
</script>

<style scoped>
.map {
  height: 400px;
  width: 100%;
}
</style>
