const SubscriptionState = Object.freeze({
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
  CANCELED: 'CANCELED',
  /**
   * The user is on a free plan and has no active subscription.
   */
  FREE: 'FREE',
})

export default SubscriptionState
