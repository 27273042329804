export default {
  data() {
    return {
      SubscriptionJS: null,
      isSubscriptionJsInitialized: false,
    }
  },

  mounted() {
    if (window.SubscriptionJS) {
      this.SubscriptionJS = window.SubscriptionJS
      this.isSubscriptionJsInitialized = true
    } else {
      const script = document.createElement('script')
      script.setAttribute('src', import.meta.env.VITE_APP_BILLWERK_SDK_ENDPOINT)
      script.setAttribute('type', 'text/javascript')
      script.setAttribute('async', true)
      script.onload = () => {
        this.SubscriptionJS = window.SubscriptionJS
        this.isSubscriptionJsInitialized = true
      }
      this.$el.appendChild(script)
    }
  },
}
