<template>
  <v-slide-y-transition>
    <v-card
      v-if="isDetailsOpen"
      flat
    >
      <v-card-text>
        <v-divider />
        <v-row>
          <v-col
            cols="12"
            lg="9"
          >
            <p class="mt-4">
              {{ $t('score-details.title', { platform: readablePlatformName }) }}
            </p>
          </v-col>
        </v-row>
      </v-card-text>

      <v-row>
        <v-col
          cols="9"
          md="6"
          lg="3"
        >
          <ScoreChart
            :score="scores.emailScore"
            :label="$t('labels.score-chart.emails', { amount: scores.emailAmount })"
          />
        </v-col>
        <v-col
          cols="9"
          md="6"
          lg="3"
        >
          <ScoreChart
            :score="scores.phoneScore"
            :label="$t('labels.score-chart.return-calls', { amount: scores.phoneAmount })"
          />
        </v-col>
        <v-col
          cols="9"
          md="6"
          lg="3"
        >
          <ScoreChart
            :score="scores.valuationScore"
            :label="$t('labels.score-chart.valuations', { amount: scores.valuationAmount })"
          />
        </v-col>
        <v-col
          cols="9"
          md="6"
          lg="3"
        >
          <ScoreChart
            :score="scores.propertyRequestScore"
            :label="
              $t('labels.score-chart.property-requests', { amount: scores.propertyRequestAmount })
            "
          />
        </v-col>
      </v-row>
    </v-card>
  </v-slide-y-transition>
</template>

<script>
import brandingMixin from '@/mixins/branding'
import ScoreChart from '@/modules/score/ScoreChart.vue'

export default {
  components: { ScoreChart },
  mixins: [brandingMixin],
  props: {
    isDetailsOpen: {
      type: Boolean,
      default: false,
    },
    scores: {
      type: Object,
      default: () => ({}),
    },
  },
}
</script>
