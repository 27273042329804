<template>
  <v-card
    v-if="!crmConfig?.hasBeenActivated && !isCrmActive && isVerified"
    border
    class="mb-4 header mt-md-4"
  >
    <v-card-title>
      <v-row>
        <v-col cols="12">
          <v-icon
            class="mr-2"
            color="black"
          >
            mdi-account-sync-outline
          </v-icon>
          <span style="word-break: break-word">{{ $t('activation-reminder.title') }}</span>
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col cols="12">
          <div class="d-flex flex-column flex-sm-row align-center">
            <div>
              {{ $t('activation-reminder.body-texts.0', { crm: readableCrmName }) }}
            </div>
            <v-btn
              :class="{ 'ml-4': $vuetify.display.smAndUp, 'mt-4 w-full': $vuetify.display.xsOnly }"
              color="primary"
              @click="$router.push('/settings/crm')"
            >
              {{ $t('buttons.activation-reminder.cta') }}
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import crmMixin from '@/mixins/crm'
import GET_FLOWFACT_CONFIG from '@/modules/settings/crmSettings/flowfact/queries/FlowfactConfig.gql'
import GET_ONOFFICE_CONFIG from '@/modules/settings/crmSettings/onoffice/queries/OnofficeConfig.gql'
import GET_PROPSTACK_CONFIG from '@/modules/settings/crmSettings/propstack/queries/PropstackConfig.gql'

export default {
  mixins: [crmMixin],
  computed: {
    isVerified() {
      return (
        this.flowfactConfig?.isTokenVerified ||
        this.propstackConfig?.isTokenVerified ||
        this.onofficeConfig?.isTokenVerified
      )
    },
  },
  apollo: {
    flowfactConfig: {
      query: GET_FLOWFACT_CONFIG,
      variables() {
        return {
          companyId: this.$auth.user.companyId,
        }
      },
      skip() {
        return (
          this.hasBeenActivated || this.isCrmActive || this.crmConfig?.crmProvider !== 'FLOWFACT'
        )
      },
    },
    propstackConfig: {
      query: GET_PROPSTACK_CONFIG,
      variables() {
        return {
          companyId: this.$auth.user.companyId,
        }
      },
      skip() {
        return (
          this.hasBeenActivated || this.isCrmActive || this.crmConfig?.crmProvider !== 'PROPSTACK'
        )
      },
    },
    onofficeConfig: {
      query: GET_ONOFFICE_CONFIG,
      variables() {
        return {
          companyId: this.$auth.user.companyId,
        }
      },
      skip() {
        return (
          this.hasBeenActivated || this.isCrmActive || this.crmConfig?.crmProvider !== 'ONOFFICE'
        )
      },
    },
  },
}
</script>
