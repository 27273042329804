import { ApolloClient, InMemoryCache } from '@apollo/client/core'
import { setContext } from '@apollo/client/link/context'
import { createApolloProvider } from '@vue/apollo-option'
import { createUploadLink } from 'apollo-upload-client'
import { watch } from 'vue'

import { getInstance } from './auth'

const getToken = async () => {
  const authService = await getInstance()
  return new Promise((resolve) => {
    if (!authService.loading) {
      resolve(authService.getTokenSilently())
      return
    }

    const stopWatch = watch(
      () => authService.loading,
      (loading) => {
        if (loading === false) {
          stopWatch()
          resolve(authService.getTokenSilently())
        }
      },
      { immediate: true }
    )
  })
}

// Replace httpLink with uploadLink
const uploadLink = createUploadLink({
  uri: import.meta.env.VITE_APP_GRAPHQL_HTTP,
})

const authLink = setContext(async (_, { headers, withoutAuth }) => {
  try {
    if (withoutAuth) {
      return {
        headers: {
          ...headers,
          'apollo-require-preflight': true,
        },
      }
    }
    const token = await getToken()
    return {
      headers: {
        ...headers,
        Authorization: `Bearer ${token}`,
        'apollo-require-preflight': true,
      },
    }
  } catch (error) {
    logError(`Auth0 error: ${error.error_description}`)
    if (!withoutAuth) {
      const authService = await getInstance()
      authService.logout()
    }
  }
})

function createApolloClient(options = {}) {
  const cache = new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          featuresForCompany: {
            merge: false,
          },
          features: {
            merge: false,
          },
        },
      },
      Company: {
        fields: {
          state: {
            merge(existing, incoming) {
              // If incoming has different shape than existing, prefer incoming
              if (incoming.value && existing?.stringValues) {
                return incoming
              }
              if (existing?.value && incoming.stringValues) {
                return existing
              }
              // Otherwise merge them
              return {
                __typename: 'State',
                ...existing,
                ...incoming,
              }
            },
          },
        },
      },
    },
    dataIdFromObject(responseObject) {
      if (responseObject.__typename) {
        if (responseObject.id) {
          return `${responseObject.__typename}:${responseObject.id}`
        }
        // For State objects that don't have an ID
        if (responseObject.__typename === 'State') {
          const identifier =
            responseObject.value ||
            (responseObject.stringValues && responseObject.stringValues.join(','))
          return `State:${identifier}`
        }
      }
      return null
    },
  })

  return new ApolloClient({
    link: options.link !== undefined ? uploadLink : authLink.concat(uploadLink),
    cache,
    name: 'makler-app-' + import.meta.env.VITE_APP_STAGE,
    version: import.meta.env.COMMIT_REF,
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'network-only',
      },
      query: {
        fetchPolicy: 'network-only',
      },
      mutate: {
        fetchPolicy: 'no-cache',
      },
    },
  })
}

function logError(message) {
  // eslint-disable-next-line
  console.log(
    '%cError',
    'background: red; color: white; padding: 2px 4px; border-radius: 3px; font-weight: bold;',
    message
  )
}

export function createProvider(options = {}) {
  const defaultClient = createApolloClient(options)
  const publicClient = createApolloClient({
    ...options,
    link: undefined,
  })

  const apolloProvider = createApolloProvider({
    defaultClient,
    clients: {
      public: publicClient,
      default: defaultClient,
    },
    defaultOptions: {
      $query: {
        fetchPolicy: 'network-only',
      },
    },
    errorHandler(error) {
      logError(error.message)
    },
  })

  return {
    install(app) {
      app.use(apolloProvider)
      return apolloProvider
    },
    defaultClient,
    clients: {
      public: publicClient,
      default: defaultClient,
    },
  }
}

export default createProvider
