const manualAppCuesFlowIds = {
  'import-contact': '2a0a0cf6-5c42-4b6c-b110-d08309abd418',
  'invite-contact': 'f12d3933-e3fa-4754-882e-bdc8e12c9ab5',
}

export function triggerAppcuesFlow({ externalFlowId, slug }) {
  if (window.Appcues) {
    const externalId = manualAppCuesFlowIds[slug] || externalFlowId
    if (externalId) {
      window.Appcues.show(externalId)
    }
  }
}
