import bus, { eventNames } from '@/lib/eventBus'
import { ProductType } from '@/lib/productTypes'
import brandingMixing from '@/mixins/branding'
import subscriptionJsMixin from '@/modules/billwerk/lib/subscriptionJsMixin'
import COMPANY from '@/modules/company/queries/Company.gql'
import SubscriptionStateValue from '@/modules/settings/accountSettings/enums/SubscriptionState'
import SELF_SERVICE_PORTAL_URL from '@/modules/settings/accountSettings/queries/SelfServicePortalUrl.gql'

export default {
  mixins: [subscriptionJsMixin, brandingMixing],
  data: function () {
    return {
      SubscriptionStateValue,
      portal: null,
      paymentMethod: null,
      paymentInfo: null,
      subscriptionState: '',
      contractDetails: {
        endDate: null,
        endDateIfCancelledNow: null,
      },
    }
  },
  provide() {
    return {
      contractDetails: this.contractDetails,
    }
  },
  watch: {
    isSubscriptionJsInitialized() {
      this.initializeContractEndpoint()
      this.getContractDetails()
    },
    billingSelfServicePortalDetails() {
      this.initializeContractEndpoint()
      this.getContractDetails()
    },
  },

  computed: {
    isCreditCard() {
      return this.paymentMethod === 'CreditCard:Reepay'
    },
    isDebit() {
      return this.paymentMethod === 'Debit:Reepay'
    },
    isInvoice() {
      return this.paymentMethod === 'BlackLabel:InvoicePayment'
    },
    isNone() {
      return this.paymentMethod === 'none:none'
    },
    isBeginner() {
      return this.company?.productType === ProductType.BEGINNER
    },
  },

  methods: {
    initializeContractEndpoint() {
      if (this.portal) return
      if (this.isSubscriptionJsInitialized && this.billingSelfServicePortalDetails) {
        this.portal = new this.SubscriptionJS.Portal(this.billingSelfServicePortalDetails.Token)
      } else if (this.billingSelfServicePortalDetails === null) {
        this.subscriptionState = SubscriptionStateValue.FREE
      }
    },
    getContractDetails() {
      if (!this.portal) return
      this.portal.contractDetails(
        (data) => {
          // Contract Details
          this.contractDetails = {
            endDate: data.Contract.EndDate ? new Date(data.Contract.EndDate) : null,
            endDateIfCancelledNow: data.EndDateIfCancelledNow
              ? new Date(data.EndDateIfCancelledNow)
              : null,
          }

          if (this.contractDetails.endDate) {
            this.subscriptionState = SubscriptionStateValue.CANCELED
          } else if (this.isPlatformMarkero) {
            this.subscriptionState = this.isBeginner
              ? SubscriptionStateValue.FREE
              : SubscriptionStateValue.ACTIVE
          } else {
            this.subscriptionState = SubscriptionStateValue.ACTIVE
          }

          // Payment Data
          const providerRole = data.Contract.PaymentProviderRole || 'none'
          const provider = data.Contract.PaymentProvider || 'none'
          this.paymentMethod = `${providerRole}:${provider}`

          if (this.paymentMethod === 'Debit:FakeProvider') this.paymentMethod = 'Debit:Reepay'
          if (this.isCreditCard) {
            this.paymentInfo = {
              cardType: data.Contract.PaymentBearer.CardType,
              maskedCard: data.Contract.PaymentBearer.MaskedCardPan,
              name: `${data.Customer.FirstName || ''} ${data.Customer.LastName || ''}`.trim(),
            }
          } else if (this.isDebit) {
            this.paymentInfo = {
              signatureDate: data.Contract.PaymentBearer.MandateSignatureDate,
              IBAN: data.Contract.PaymentBearer.IBAN,
              name: data.Contract.PaymentBearer.Holder,
            }
          }
        },
        () => {
          bus.$emit(eventNames.SHOW_SNACKBAR, {
            color: 'error',
            text: 'Fehler beim Laden Ihrer Vertragsdaten, bitte versuchen Sie es später erneut.',
          })
        }
      )
    },
  },
  apollo: {
    billingSelfServicePortalDetails: {
      query: SELF_SERVICE_PORTAL_URL,
    },
    company: {
      query: COMPANY,
      variables() {
        return {
          id: this.$auth.user.companyId,
        }
      },
    },
  },
}
