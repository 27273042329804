<template>
  <v-menu
    style="z-index: 1000000"
    v-model="isUserMenuOpen"
    location="top"
    @mounted="onMounted"
  >
    <template v-slot:activator="{ props }">
      <v-list-item
        density="compact"
        v-bind="props"
        class="rounded-lg user-item"
        :class="{
          'bg-grey-lighten-3 pl-1': isDrawerOpen,
          'bg-white': !isDrawerOpen,
        }"
        @click="trackClick('User Menu')"
      >
        <template #prepend>
          <v-avatar
            :style="{
              '--v-avatar-height': '36px',
            }"
            class="mr-2"
          >
            <PackageLogo
              :current-package="productType"
              :show-label="false"
              size="medium"
            />
          </v-avatar>
        </template>
        <template #title>
          <span
            v-if="$auth.user.given_name && $auth.user.family_name"
            class="text-caption font-weight-bold"
          >
            {{ $auth.user.given_name + ' ' + $auth.user.family_name }}
          </span>
          <span
            v-else
            class="text-caption font-weight-bold d-inline-block text-truncate"
            style="max-width: 140px"
          >
            {{ $auth.user.email }}
          </span>
        </template>
        <template #subtitle>
          <span
            v-if="companyName"
            class="text-caption font-weight-bold grey--text d-inline-block text-truncate"
            style="max-width: 140px"
          >
            {{ companyName }}
          </span>
        </template>

        <template #append>
          <v-icon size="small">
            {{ isUserMenuOpen ? 'mdi-arrow-down' : 'mdi-arrow-up' }}
          </v-icon>
        </template>
      </v-list-item>
    </template>

    <v-list
      density="compact"
      nav
    >
      <v-list-item
        to="/settings/profile"
        density="compact"
        exact
        link
        @click="trackClick('Profile')"
      >
        <template #prepend>
          <v-icon>mdi-account</v-icon>
        </template>
        <template #title>
          <v-list-item-title>{{ $t('navigation.labels.profile') }}</v-list-item-title>
        </template>
      </v-list-item>
      <v-list-item @click="logout">
        <template #prepend>
          <v-icon>mdi-logout</v-icon>
        </template>
        <template #title>
          <v-list-item-title>{{ $t('navigation.labels.logout') }}</v-list-item-title>
        </template>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script lang="ts" setup>
import { getCurrentInstance, onMounted, PropType, ref } from 'vue'

import PackageLogo from '@/components/PackageLogo.vue'

const { appContext } = getCurrentInstance()!
const $auth = appContext.config.globalProperties.$auth
const isUserMenuOpen = ref(false)

defineProps<{
  isDrawerOpen: PropType<boolean>
  productType: PropType<string>
  companyName?: PropType<string>
}>()

const emit = defineEmits<{
  'track-click': () => PropType<void>
  mounted: () => PropType<void>
  logout: () => PropType<void>
}>()

const trackClick = (label: string) => emit('track-click', label)
const logout = () => emit('logout')

onMounted(() => emit('mounted'))
</script>

<style scoped>
.user-item {
  transition: background-color 0.4s;
}
</style>
