<template>
  <v-menu
    :model-value="userMenuOpen"
    :close-on-content-click="true"
    @update:model-value="updateUserMenu"
  >
    <template v-slot:activator="{ props }">
      <div
        class="d-flex align-center cursor-pointer user-section"
        v-bind="props"
      >
        <v-list-item class="py-0 px-0">
          <div class="d-flex align-center w-100 ml-2">
            <v-img
              v-if="userPicture"
              alt="User Avatar"
              class="portrait"
              :src="userPicture"
            />
            <div class="d-flex flex-column flex-grow-1 user-details">
              <div class="pl-0">
                <v-list-item-title
                  v-if="userName"
                  class="truncate mb-0"
                  :title="userName"
                >
                  {{ userName }}
                </v-list-item-title>
                <v-list-item-subtitle
                  v-if="companyName"
                  class="text-caption grey--text truncate pl-0"
                  :title="companyName"
                >
                  {{ companyName }}
                </v-list-item-subtitle>
              </div>
              <v-spacer />
            </div>
            <v-btn
              v-if="isDrawerOpen"
              icon
              size="medium"
              class="menu-button"
              :ripple="false"
            >
              <v-icon class="text-grey-lighten-1">mdi-dots-vertical</v-icon>
            </v-btn>
          </div>
        </v-list-item>
      </div>
    </template>

    <v-list style="z-index: 1000000">
      <v-list-item
        to="/settings/profile"
        density="compact"
        exact
        link
        @click="emitTrackClick('Profile')"
      >
        <template #prepend>
          <v-icon class="mr-2">mdi-account</v-icon>
        </template>
        <v-list-item-title>Profile</v-list-item-title>
      </v-list-item>
      <v-list-item
        density="compact"
        @click="emitLogout"
      >
        <template #prepend>
          <v-icon class="mr-2">mdi-logout</v-icon>
        </template>
        <v-list-item-title>Logout</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script lang="ts" setup>
import { PropType, ref } from 'vue'

defineProps<{
  isDrawerOpen?: PropType<boolean>
  userPicture?: PropType<string>
  userName?: PropType<string>
  companyName?: PropType<string>
}>()

const userMenuOpen = ref(false)

const emit = defineEmits<{
  'track-click': () => PropType<void>
  logout: () => PropType<void>
}>()

const updateUserMenu = (value: boolean) => (userMenuOpen.value = value)
const emitTrackClick = (label: string) => emit('track-click', label)
const emitLogout = () => emit('logout')
</script>

<style scoped>
.user-details .truncate {
  font-size: 14px;
  font-weight: 400;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.portrait {
  border-radius: 100%;
  object-fit: cover;
  width: 34px;
  flex: unset;
  margin-right: 8px;
}
.user-section {
  position: relative;
  width: 100%;
  z-index: 100000;
}

.user-section :deep(.v-list-item--variant-text) {
  min-width: 100%;
}

.user-section .truncate {
  max-width: calc(100% - 20px);
  min-width: 0;
}

.user-section .v-list-item-content > div {
  min-width: 0;
}

.user-details {
  min-width: 0;
  flex: 1;
  margin-right: 8px;
}

.truncate {
  display: block;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.menu-button {
  background-color: transparent !important;
  position: absolute;
  right: 8px;
}

.user-section:hover .menu-button {
  background-color: rgba(0, 0, 0, 0.04) !important;
}

.portrait {
  border-radius: 100%;
  object-fit: cover;
  width: 34px;
}
</style>
