<template>
  <v-dialog
    v-if="useLegacyCookieConsent"
    v-model="showDialog"
    width="600"
    persistent
  >
    <v-card>
      <v-card-title class="pa-0">
        <v-toolbar
          color="primary"
          dark
          flat
        >
          <v-toolbar-title>Cookie Hinweis und Einstellungen</v-toolbar-title>
          <v-spacer />
        </v-toolbar>
      </v-card-title>

      <v-card-text class="pt-0 pb-2 pl-3 pr-3 mt-4 pt-sm-0 pr-sm-6 pb-sm-5 pl-sm-6">
        <p>
          Wir verwenden Cookies, um Ihnen einen optimalen Service zu bieten und auf Basis von
          Analysen unsere Webseiten weiter zu verbessern. Sie können selbst entscheiden, welche
          Cookies wir verwenden dürfen.
        </p>
        <p>
          Bitte beachten Sie, dass technisch notwendige Cookies gesetzt werden müssen, um den
          Betrieb der {{ readablePlatformName }} App sicherstellen zu können.
        </p>

        <p class="mb-0 mb-sm-2">
          <a
            :href="publicDataPrivacyUrl"
            target="_blank"
            rel="noopener noreferrer"
            >{{ $t('legal.topic.privacy.title') }}</a
          >
          <a
            :href="imprintUrl"
            target="_blank"
            rel="noopener noreferrer"
            class="ml-4"
            >{{ $t('legal.topic.imprint.title') }}</a
          >
        </p>
        <v-list class="checkbox-list">
          <v-list-item
            v-for="cookieType in cookieTypes"
            :key="cookieType.value"
            class="ma-0 pa-0 flex align-center"
            @click="toggleChoiceOption(cookieType)"
          >
            <template #prepend>
              <v-checkbox
                v-model="choice"
                :class="cookieType.showDescription && 'top-aligned'"
                color="primary"
                :value="cookieType.value"
                :readonly="cookieType.readOnly"
                @click="toggleChoiceOption(cookieType)"
              />
            </template>

            <template #default>
              <div :class="['v-list-item__content', cookieType.showDescription && 'top-aligned']">
                <div class="v-list-item-title">{{ cookieType.label }}</div>
                <v-expand-transition>
                  <p
                    v-if="cookieType.showDescription"
                    class="pt-2 text-grey-darken-1 description"
                  >
                    {{ cookieType.description }}
                  </p>
                </v-expand-transition>
              </div>
            </template>

            <template #append>
              <v-btn
                :class="cookieType.showDescription && 'top-aligned'"
                icon
                color="transparent"
                @click.stop="toggleDescription(cookieType.value)"
              >
                <v-icon color="grey lighten-1"> mdi-information </v-icon>
              </v-btn>
            </template>
          </v-list-item>
        </v-list>
      </v-card-text>

      <v-divider class="d-none d-sm-block" />

      <v-card-actions class="px-4 py-4 flex-column flex-sm-row">
        <v-btn
          color="primary"
          variant="text"
          @click="acceptSelected"
        >
          Auswahl bestätigen
        </v-btn>
        <v-spacer />
        <v-btn
          color="primary"
          data-testid="cookies-submit"
          @click="acceptAll"
        >
          Alle Cookies akzeptieren
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { computed, onMounted, ref } from 'vue'

import { dataPrivacy } from '@/lib/features'
import brandingMixin from '@/mixins/branding'
import { useCookieConsent } from '@/services/cookieConsent'
import { useGTM } from '@/services/gtm'

const cookieTypes = [
  {
    value: 'mandatory',
    label: 'Notwendige Cookies',
    description: `Diese Cookies dienen dem technisch einwandfreien Betrieb
    der ${brandingMixin.computed.readablePlatformName()} App und können nicht deaktiviert werden.`,
    readOnly: true,
  },
  {
    value: 'tracking',
    label: 'Analyse-Cookies',
    description: `Diese Cookies helfen uns die Qualität der ${brandingMixin.computed.readablePlatformName()} App zu
    verbessern. Durch diese Cookies erfahren wir, wie die App benutzt wird
    und können sie so stetig verbessern.`,
  },
].map((type) => ({
  ...type,
  showDescription: false,
}))

export default {
  name: 'CookieConsentDialog',
  mixins: [brandingMixin],

  setup() {
    const cookieConsent = useCookieConsent()
    const { gtm, enableGTM } = useGTM()
    const showDialog = ref(false)
    const choice = ref(['mandatory'])
    const cookieTypesList = ref(cookieTypes)

    if (gtm) {
      enableGTM(true)
    }

    const useLegacyCookieConsent = computed(() => {
      return dataPrivacy?.isActive
    })

    const toggleChoiceOption = (cookieType) => {
      if (cookieType.readOnly) {
        return
      }

      const choiceIndex = choice.value.indexOf(cookieType.value)
      if (choiceIndex === -1) {
        choice.value = [...choice.value, cookieType.value]
      } else {
        const newChoice = [...choice.value]
        newChoice.splice(choiceIndex, 1)
        choice.value = newChoice
      }
    }

    const toggleDescription = (cookieTypeValue) => {
      cookieTypesList.value = cookieTypesList.value.map((item) => ({
        ...item,
        showDescription:
          item.value === cookieTypeValue ? !item.showDescription : item.showDescription,
      }))
    }

    const accept = (selectedChoice) => {
      cookieConsent.accept(selectedChoice)
      showDialog.value = false
      if (selectedChoice.includes('tracking') && gtm) {
        gtm.enable(true)
      }
    }

    const acceptAll = () => {
      const allChoices = cookieTypesList.value.map((ct) => ct.value)
      accept(allChoices)
    }

    const acceptSelected = () => {
      accept(choice.value)
    }

    onMounted(() => {
      const accepted = cookieConsent.hasAccepted()
      const isOnoffice = Boolean(sessionStorage.getItem('onOffice'))
      if (
        !(
          accepted ||
          isOnoffice ||
          window.location.href.match(/onoffice\/(activate|service)/) ||
          window.location.href.includes('/products')
        )
      ) {
        showDialog.value = true
      }
    })

    return {
      cookieTypes: cookieTypesList,
      showDialog,
      choice,
      useLegacyCookieConsent,
      toggleChoiceOption,
      toggleDescription,
      acceptAll,
      acceptSelected,
    }
  },
}
</script>

<style scoped>
.top-aligned {
  align-self: flex-start;
}

.description {
  line-height: inherit;
}
.checkbox-list :deep(.v-input--horizontal) {
  grid-template-areas: none;
}
</style>
