<template>
  <div v-if="checklistId && isProductFruitsListVisible">
    <v-card
      border
      class="px-0 mt-4 d-flex flex-column mb-4"
    >
      <v-card-text class="px-0">
        <div
          id="checklist"
          :class="{ 'small-checklist': loading, 'mobile-checklist': !loading }"
          class="checklist"
        >
          <div v-if="!loading" />
          <div
            v-else
            class="w-full d-flex justify-space-around"
          >
            <v-progress-circular
              indeterminate
              :size="50"
              :width="5"
              color="primary"
            />
          </div>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import { productFruits } from 'product-fruits'

import { showSnackbarMessage } from '@/lib/snackbarMessages'

export default {
  data() {
    return {
      loading: true,
      hasChecklist: true,
    }
  },
  computed: {
    isMobile() {
      return this.$vuetify.display.smAndDown
    },
    isProductFruitsListVisible() {
      return this.hasChecklist
    },
    checklistId() {
      return this.isMobile
        ? import.meta.env.VITE_APP_PRODUCT_FRUITS_CHECKLIST_CODE_FIRST_STEPS_MOBILE
        : import.meta.env.VITE_APP_PRODUCT_FRUITS_CHECKLIST_CODE_FIRST_STEPS
    },
  },
  mounted() {
    if (this.checklistId) {
      productFruits.safeExec(() => {
        this.setupChecklist()
      })
    }
  },
  methods: {
    setupChecklist() {
      this.injectChecklistToElement()
      this.listenToListChanges()
    },
    injectChecklistToElement() {
      const el = document.getElementById('checklist')
      try {
        window.productFruits.api.checklists.injectToElement(this.checklistId, el)
      } catch (e) {
        this.hasChecklist = false
      }
      this.loading = false
    },
    listenToListChanges() {
      const checklists = window.productFruits.api.checklists
      checklists.listen('dismissed', this.handleDismissedEvent)
      checklists.listen('completed', this.handleCompletedEvent)
    },

    handleDismissedEvent() {
      showSnackbarMessage('info', this.$t('alerts.product-fruits.info'))
      this.hasChecklist = false
    },

    handleCompletedEvent() {
      showSnackbarMessage('info', this.$t('alerts.product-fruits.success'))
      this.hasChecklist = false
    },
  },
}
</script>

<style scoped>
.checklist {
  height: 450px;
  overflow-y: auto;
}
.small-checklist {
  height: 100px;
}

@media (max-width: 320px) {
  .mobile-checklist {
    height: 1150px;
  }
}

@media (min-width: 321px) and (max-width: 393px) {
  .mobile-checklist {
    height: 870px;
  }
}

@media (min-width: 394px) and (max-width: 430px) {
  .mobile-checklist {
    height: 820px;
  }
}
</style>
