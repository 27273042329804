import * as Sentry from '@sentry/vue'
import { jwtDecode } from 'jwt-decode'
import { reactive } from 'vue'

import bus, { eventNames } from '@/lib/eventBus'
import { onoffice } from '@/lib/features'

function inIframe() {
  try {
    return window.self !== window.top
  } catch {
    return true
  }
}

export function isOnofficeProvider() {
  if (!onoffice?.isEnabled || !inIframe()) {
    return false
  }

  const token = sessionStorage.getItem('onOffice')
  if (token) {
    const jwt = jwtDecode(token)
    if (jwt.exp * 1000 > Date.now()) {
      return true
    }
  }

  if (!window.location.href.includes('onoffice/service')) {
    return false
  }

  const params = Object.fromEntries(new URLSearchParams(window.location.search))
  return ['apiClaim', 'timestamp', 'customerName', 'customerWebId', 'userId', 'signature'].every(
    (key) => params[key]
  )
}

export const onofficeAuth = reactive({
  loading: true,
  isAuthenticated: false,
  token: null,
  user: {},
  error: null,

  async onCreated() {
    await this.login()

    setTimeout(
      () => {
        window.alert('Ihre Sitzung ist abgelaufen. Bitte laden Sie das Fenster neu.')
        Sentry.captureMessage('onOffice Session expired')
      },
      11 * 60 * 60 * 1000
    )

    if (this.isAuthenticated) {
      bus.$emit(eventNames.USER_LOGGED_IN, this.user.companyId)
    }

    Sentry.configureScope((scope) => {
      if (this.user) {
        scope.setTag('companyId', this.user.companyId)
        scope.setUser({ id: this.user.userId })
      } else {
        scope.clear()
      }
    })
  },

  async login() {
    this.loading = true
    const token = sessionStorage.getItem('onOffice')

    if (token) {
      try {
        const jwt = jwtDecode(token)
        if (jwt.exp * 1000 > Date.now()) {
          this.token = token
          this.user = jwt.user
          this.isAuthenticated = true
          this.loading = false
          return
        }
      } catch {
        console.error('invalid token')
      }
    }

    const marketplaceApp = (window.location.href.match(/service\/(.*)\?/) || [])[1]?.toUpperCase()

    try {
      const axios = (await import('axios')).default
      const {
        data: { token, user },
      } = await axios.post(import.meta.env.VITE_APP_BOTTIMMO_SERVICE + '/onoffice/login', {
        url: window.location.href,
        marketplaceApp,
      })

      sessionStorage.setItem('onOffice', token)
      this.token = token
      this.user = user
    } catch (error) {
      this.error =
        error?.response.status === 404
          ? 'userNotFound'
          : error?.response.status === 403
            ? 'marketplaceAppNotAllowed'
            : 'unknown'
    } finally {
      this.isAuthenticated = Boolean(this.token)
      this.loading = false
    }
  },

  logout() {
    sessionStorage.removeItem('onOffice')
    // eslint-disable-next-line no-undef
    if (processTypeToonOffice) {
      // eslint-disable-next-line no-undef
      processTypeToonOffice('onOffice.service.closeProviderWindow')
    }
  },

  getTokenSilently() {
    return this.token
  },

  changePassword() {
    throw new Error('changePassword not Possible for onOffice user')
  },

  getIdTokenClaims() {
    throw new Error('getIdTokenClaims not Possible for onOffice user')
  },
})
